<template>
  <div id="layoutSidenav_nav">
    <nav class="sidenav shadow-right sidenav-light">
      <div class="sidenav-menu">
        <div class="nav accordion" id="accordionSidenav">
          <div class="sidenav-menu-heading">Home</div>
          <router-link class="nav-link" to="/">
            <div class="nav-link-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-activity"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"
                />
              </svg>
            </div>
            Dashboard
          </router-link>

          <div v-if="user.role == 'Admin'">
            <div class="sidenav-menu-heading">Admin</div>
            <a
              class="nav-link collapsed"
              href="javascript:void(0);"
              data-toggle="collapse"
              data-target="#collapseSetup"
              aria-expanded="false"
              aria-controls="collapseSetup"
            >
              <div class="nav-link-icon"><i data-feather="repeat"></i></div>
              Setup
              <div class="sidenav-collapse-arrow">
                <i class="fas fa-angle-down"></i>
              </div>
            </a>
            <div
              class="collapse"
              id="collapseSetup"
              data-parent="#accordionSidenav"
            >
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/roles">Roles</router-link>
              </nav>
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/rates">Rates</router-link>
              </nav>
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/parkingtypes"
                  >Parking Types</router-link
                >
              </nav>
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/voyagetypes"
                  >Voyage Types</router-link
                >
              </nav>
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/covertypes"
                  >Cover Types</router-link
                >
              </nav>
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/natureofcargos"
                  >Nature Of Cargo</router-link
                >
              </nav>
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/conveyance"
                  >Conveyance</router-link
                >
              </nav>
            </div>

            <a
              class="nav-link collapsed"
              href="javascript:void(0);"
              data-toggle="collapse"
              data-target="#collapseUsers"
              aria-expanded="false"
              aria-controls="collapseUsers"
            >
              <div class="nav-link-icon"><i data-feather="repeat"></i></div>
              Users
              <div class="sidenav-collapse-arrow">
                <i class="fas fa-angle-down"></i>
              </div>
            </a>
            <div
              class="collapse"
              id="collapseUsers"
              data-parent="#accordionSidenav"
            >
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/users"
                  >All Users</router-link
                >
              </nav>
              <nav class="sidenav-menu-nested nav">
                <router-link class="nav-link" to="/users/newuser"
                  >Create User</router-link
                >
              </nav>
            </div>
          </div>

          <div v-if="user.role == 'Broker'">
            <div class="sidenav-menu-heading">Broker</div>
            <router-link class="nav-link" to="/broker/buymarine">
              <div class="nav-link-icon">
                <i data-feather="bar-chart"></i>
              </div>
              Buy marine
            </router-link>

            <router-link class="nav-link" to="/broker/allBookedBusinesses">
              <div class="nav-link-icon">
                <i data-feather="bar-chart"></i>
              </div>
              Booked Businesses
            </router-link>
          </div>

          <div v-if="user.role == 'Agent'">
            <div class="sidenav-menu-heading">Agent</div>
            <router-link class="nav-link" to="/agent/buymarine">
              <div class="nav-link-icon">
                <i data-feather="bar-chart"></i>
              </div>
              Buy marine
            </router-link>

            <router-link class="nav-link" to="/agent/allBookedBusinesses">
              <div class="nav-link-icon">
                <i data-feather="bar-chart"></i>
              </div>
              Booked Businesses
            </router-link>
          </div>
        </div>
      </div>
      <div class="sidenav-footer">
        <div class="sidenav-footer-content">
          <div class="sidenav-footer-subtitle">
            Logged in as {{ user.role }}:
          </div>
          <div class="sidenav-footer-title">{{ user.broker }}</div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { useMainStore } from "../stores/main";
import { mapState } from "pinia";
export default {
  name: "Sidebar",

  computed: {
    ...mapState(useMainStore, ["user"]),
  },
};
</script>

<style>
.router-link-active {
  color: #990100 !important;
}
</style>
