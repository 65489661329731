<template v-if="isLoggedIn">
  <div :class="[toggleNav ? 'sidenav-toggled' : '']">
    <Topbar :toggleSidebar="toggleSidebar" />
    <div id="layoutSidenav">
      <Sidebar />
      <div id="layoutSidenav_content">
        <slot />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { useMainStore } from "../stores/main";
import { mapState } from "pinia";
import Sidebar from "../components/Sidebar.vue";
import Topbar from "../components/Topbar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "AppLayout",
  components: { Sidebar, Topbar, Footer },
  data() {
    return {
      toggleNav: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ["isLoggedIn"]),
  },

  methods: {
    toggleSidebar() {
      this.toggleNav = !this.toggleNav;
    },
  },

  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },
};
</script>

<style>
</style>
