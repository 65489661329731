<template>
  <nav
    class="topnav navbar navbar-expand shadow navbar-light bg-white"
    id="sidenavAccordion"
  >
    <div class="px-2">
      <img
        class="d-inline-block"
        style="width: 30px"
        src="../assets/logo2.png"
      />
      <a class="navbar-brand d-none d-sm-inline-block" href="index.html"
        >CHI Marine Portal</a
      >
    </div>
    <button
      @click.prevent="toggleSidebar()"
      class="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2"
      id="sidebarToggle"
      href="#"
    >
      <i class="bi bi-list" style="font-size: 1.5rem"></i>
    </button>
    <form class="form-inline mr-auto d-none d-lg-block">
      <input
        class="form-control form-control-solid mr-sm-2"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
    </form>
    <ul class="navbar-nav align-items-center ml-auto">
      <li class="nav-item dropdown no-caret mr-3">
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdownDocs"
          href="javascript:void(0);"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="d-inline d-md-none font-weight-500">Docs</div>
          <div class="d-none d-md-inline font-weight-500">Documentation</div>
          <i class="fas fa-chevron-right dropdown-arrow"></i>
        </a>
        <div
          class="
            dropdown-menu dropdown-menu-right
            py-0
            o-hidden
            mr-n15 mr-lg-0
            animated--fade-in-up
          "
          aria-labelledby="navbarDropdownDocs"
        >
          <!-- <a
            class="dropdown-item py-3"
            href="https://docs.startbootstrap.com/sb-admin-pro"
            target="_blank"
          >
            <div class="icon-stack bg-primary-soft text-primary mr-4">
              <i data-feather="book"></i>
            </div>
            <div>
              <div class="small text-gray-500">Documentation</div>
              Download User Manual
            </div>
          </a> -->
          <div class="dropdown-divider m-0"></div>
          <!-- <a
            class="dropdown-item py-3"
            href="https://docs.startbootstrap.com/sb-admin-pro/components"
            target="_blank"
          >
            <div class="icon-stack bg-primary-soft text-primary mr-4">
              <i data-feather="code"></i>
            </div>
            <div>
              <div class="small text-gray-500">Components</div>
              Code snippets and reference
            </div>
          </a>
          <div class="dropdown-divider m-0"></div> -->
          <!-- <a
            class="dropdown-item py-3"
            href="https://docs.startbootstrap.com/sb-admin-pro/changelog"
            target="_blank"
          >
            <div class="icon-stack bg-primary-soft text-primary mr-4">
              <i data-feather="file-text"></i>
            </div>
            <div>
              <div class="small text-gray-500">Changelog</div>
              Updates and changes
            </div>
          </a> -->
        </div>
      </li>
      <li class="nav-item dropdown no-caret mr-3 dropdown-notifications">
        <a
          class="btn btn-icon btn-transparent-dark dropdown-toggle"
          id="navbarDropdownAlerts"
          href="javascript:void(0);"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ><i class="bi bi-bell"></i
        ></a>
        <div
          class="
            dropdown-menu dropdown-menu-right
            border-0
            shadow
            animated--fade-in-up
          "
          aria-labelledby="navbarDropdownAlerts"
        >
          <h6 class="dropdown-header dropdown-notifications-header">
            <i class="bi bi-bell mx-2"></i> Alerts Center
          </h6>
          <!-- <a class="dropdown-item dropdown-notifications-item" href="#!">
            <div class="dropdown-notifications-item-icon bg-danger">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <div class="dropdown-notifications-item-content">
              <div class="dropdown-notifications-item-content-details">
                December 8, 2019
              </div>
              <div class="dropdown-notifications-item-content-text">
                Critical system failure, systems shutting down.
              </div>
            </div> </a
          > -->
          <img style="width: 100%" src="@/assets/notis.gif" />
          <!-- <a class="dropdown-item dropdown-notifications-footer" href="#!"
            >View All Alerts</a
          > -->
          <span class="dropdown-item dropdown-notifications-footer"
            >No Available Alerts</span
          >
        </div>
      </li>
      <!-- <li class="nav-item dropdown no-caret mr-3 dropdown-notifications">
        <a
          class="btn btn-icon btn-transparent-dark dropdown-toggle"
          id="navbarDropdownMessages"
          href="javascript:void(0);"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ><i class="bi bi-envelope"></i
        ></a>
        <div
          class="
            dropdown-menu dropdown-menu-right
            border-0
            shadow
            animated--fade-in-up
          "
          aria-labelledby="navbarDropdownMessages"
        >
          <h6 class="dropdown-header dropdown-notifications-header">
            <i class="mr-2" data-feather="mail"></i>Message Center
          </h6>
          <a class="dropdown-item dropdown-notifications-item" href="#!"
            ><img
              class="dropdown-notifications-item-img"
              src="https://source.unsplash.com/vTL_qy03D1I/60x60"
            />
            <div class="dropdown-notifications-item-content">
              <div class="dropdown-notifications-item-content-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
              <div class="dropdown-notifications-item-content-details">
                Emily Fowler · 58m
              </div>
            </div> </a
          ><a class="dropdown-item dropdown-notifications-item" href="#!"
            ><img
              class="dropdown-notifications-item-img"
              src="https://source.unsplash.com/4ytMf8MgJlY/60x60"
            />
            <div class="dropdown-notifications-item-content">
              <div class="dropdown-notifications-item-content-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
              <div class="dropdown-notifications-item-content-details">
                Diane Chambers · 2d
              </div>
            </div> </a
          ><a class="dropdown-item dropdown-notifications-footer" href="#!"
            >Read All Messages</a
          >
        </div>
      </li> -->
      <li class="nav-item dropdown no-caret mr-3 dropdown-user">
        <a
          class="btn btn-icon btn-primary dropdown-toggle"
          id="navbarDropdownUserImage"
          href="javascript:void(0);"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          >{{
            isLoggedIn
              ? user.broker.split("")[0] +
                user.broker.split(" ")[1].split("")[0]
              : ""
          }}</a
        >
        <div
          class="
            dropdown-menu dropdown-menu-right
            border-0
            shadow
            animated--fade-in-up
          "
          aria-labelledby="navbarDropdownUserImage"
        >
          <h6 class="dropdown-header d-flex align-items-center">
            <div class="btn btn-icon btn-primary mx-3">
              {{
                isLoggedIn
                  ? user.broker.split("")[0] +
                    user.broker.split(" ")[1].split("")[0]
                  : ""
              }}
            </div>
            <div class="dropdown-user-details">
              <div class="dropdown-user-details-name">{{ user.broker }}</div>
              <div class="dropdown-user-details-email">{{ user.email }}</div>
            </div>
          </h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#!">
            <div class="dropdown-item-icon">
              <i data-feather="settings"></i>
            </div>
            Account </a
          ><a @click.prevent="logout()" class="dropdown-item" href="#!">
            <div class="dropdown-item-icon">
              <i data-feather="log-out"></i>
            </div>
            Logout
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useMainStore } from "../stores/main";
import { useAuthStore } from "../stores/authentication";
import { mapState, mapActions } from "pinia";
export default {
  name: "TopBar",
  props: ["toggleSidebar"],
  computed: {
    ...mapState(useMainStore, ["user", "isLoggedIn"]),
  },

  methods: {
    ...mapActions(useAuthStore, ["logout"]),
  },
};
</script>

<style>
</style>
