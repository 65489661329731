<template>
  <footer class="footer mt-auto footer-light">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 small">Copyright &copy; CHI Plc 2022</div>
        <div class="col-md-6 text-md-right small">
          <a href="https://chiplc.com/privacy/">Privacy Policy</a>
          &middot;
          <a href="https://chiplc.com/term-of-use/">Terms &amp; Conditions</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>
